import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Open Source`}</h1>
    <p>{``}<a parentName="p" {...{
        "href": "/Polis",
        "title": "Polis"
      }}>{`Polis`}</a>{` is `}<a parentName="p" {...{
        "href": "https://opensource.com/resources/what-open-source"
      }}>{`open source software`}</a>{` under the `}<a parentName="p" {...{
        "href": "/AGPL3",
        "title": "AGPL3"
      }}>{`AGPL3`}</a>{` license.`}</p>
    <p>{`Citizens should be able to audit the statistical systems that are aggregating and synthesizing their voices.
We believe systems of this kind will be central to the next forms of democracy, and that transparency will make or break trust.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      